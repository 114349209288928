import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/demo",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Reward/DemoFileCreate")),
  },
  {
    exact: true,
    path: "/select-horse",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SelectHorse")),
  },
  {
    exact: true,
    path: "/horse-store",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/HorseStore/index")),
  },
  {
    exact: true,
    path: "/view-horse-store",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/HorseStore/ViewHorseStore")),
  },
  {
    exact: true,
    path: "/leaderboard",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Rankers/index")),
  },
  {
    exact: true,
    path: "/game-interface",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SelectHorse/GameInterface")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
