import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTableCell: {
      root: {
        textAlign: "center",
      },
    },
    MuiRating: {
      root: {
        flexWrap: "wrap",
      },
      label: {
        cursor: "inherit",
        background: "#0E0B01",
        borderRadius: "5px",
        padding: "3px",
        marginRight: "5px",
        border: "1px solid #8080801f",
      },
      iconEmpty: {
        color: "#ffffff73",
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          backgroundColor: "rgba(255, 255, 255, 0.10)",
        },
      },
      root: {
        color: "#fff",
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperFullWidth: {
        background: "rgba(10, 8, 7, 1)",
        boxShadow:
          "0 1px 0 0 #FFCA64, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #CA772A, -1px 0 0 0 #CA772A, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #CA772A, 1px 1px 0 0 #f4a91b, -1px -1px 0 0 #CA772A",
      },
      paperWidthXs: {
        maxWidth: "375px",
      },
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
        padding: "20px",
      },
      paperWidthSm: {
        maxWidth: "600px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
        fontFamily: "'Readex Pro', sans-serif",
        fontWeight: "400",
      },

      containedPrimary: {
        background: "linear-gradient(164deg, #FFCA64 0%, #CA772A 100%)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "14px",
        height: "40px",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid rgb(255 255 255 / 41%)",
        whiteSpace: "pre",
        "&:hover": {
          color: "#ffffff",
          background: "rgba(255, 255, 255, 0.04)",
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          border: "1px solid rgb(255 255 255 / 10%)",
        },
      },

      containedSecondary: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "14px",
        height: "44px",
        lineHeight: " 21px",
        whiteSpace: "pre",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid rgb(255 255 255 / 10%)",
        "&:hover": {
          color: "#ffffff",
          background: "rgba(255, 255, 255, 0.05)",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          border: "1px solid rgb(229 161 71)",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#ffffff",
        fontWeight: 400,
        padding: "5px 19px",
      },

      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Readex Pro', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
